import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { StyledComboChart, StyledLoader, StyledText } from '@/components/lib';
import { AnnotationOvertimeGraphContainer } from '../OverviewNoVersionSelected/OverviewNoVersionSelected.styles';

import {
  AnnotationsMonitorSchema,
  EnvType,
  getAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPost
} from '@/helpers/services/api';

interface AnnotationOvertimeGraphProps {
  envType: EnvType;
  selectedVersionId: number;
  timestamp: { start: number; end: number };
}

const constants = {
  title: 'Score',
  graphStyles: { margin: '0 auto' },
  graphSizes: { width: '98% !important', height: '400px !important' },
  legends: ['User Annotation', 'Estimated Annotation', 'Unknown']
};

const { title, legends, graphStyles, graphSizes } = constants;

export const AnnotationOvertimeGraph = (props: AnnotationOvertimeGraphProps) => {
  const { selectedVersionId, envType, timestamp } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [annotationMonitorData, setAnnotationMonitorData] = useState<AnnotationsMonitorSchema>();

  useEffect(() => {
    setIsLoading(true);
    getAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPost(
      selectedVersionId,
      {
        end_time_epoch: timestamp.end || dayjs().unix(),
        start_time_epoch: timestamp.start || dayjs().subtract(1, 'month').unix(),
        tz: `${Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone}`
      },
      { env_type: envType }
    ).then(res => {
      setIsLoading(false);
      setAnnotationMonitorData(res);
    });
  }, [envType, timestamp?.start, timestamp?.end, selectedVersionId]);

  const labels = Array?.isArray(annotationMonitorData?.window_list)
    ? annotationMonitorData?.window_list?.map(window =>
        dayjs(window).format(annotationMonitorData?.frequency === 'HOUR' ? 'DD/MM LT' : 'll')
      )
    : [];

  const barData = Array.isArray(annotationMonitorData?.values)
    ? annotationMonitorData?.values?.map(val => [
        Number(val?.per_type_results?.good?.user?.amount || 0) + Number(val?.per_type_results?.bad?.user?.amount || 0),
        Number(val?.per_type_results?.good?.estimated?.amount || 0) +
          Number(val?.per_type_results?.bad?.estimated?.amount || 0),
        Number(val?.per_type_results?.unknown?.user?.amount || 0) +
          Number(val?.per_type_results?.unknown?.estimated?.amount || 0)
      ])
    : [];

  const lineData = Array.isArray(annotationMonitorData?.values)
    ? annotationMonitorData?.values?.map(val => Number(val?.score))
    : [];

  return (
    <AnnotationOvertimeGraphContainer type="card">
      <StyledText text={title} type="h2" />
      {isLoading ? (
        <StyledLoader />
      ) : (
        <StyledComboChart
          labels={labels}
          barData={barData}
          lineData={lineData}
          barLegends={legends}
          style={graphStyles}
          barWidth={graphSizes.width}
          barHeight={graphSizes.height}
          rawData={annotationMonitorData?.values}
        />
      )}
    </AnnotationOvertimeGraphContainer>
  );
};
